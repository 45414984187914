import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  }, {
    path: '/sign_in',
    name: 'signIn',
    component: () => import('../views/SignIn.vue')
  }, {
    path: '/anonymous',
    name: 'anonymous',
    component: () => import('../views/Anonymous.vue')
  }, {
    path: '/v/:volumeId',
    name: 'volume',
    component: () => import('../views/Volume.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/v/:volumeId/c/:contentId',
    name: 'content',
    component: () => import('../views/Content.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/v/:volumeId/survey',
    name: 'survey',
    component: () => import('../views/Survey.vue'),
    meta: { requiresAuth: true }
  }, {
    path: '/v/:volumeId/admin/dashboard',
    name: 'dashboard',
    component: () => import('../views/admin/Dashboard.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  }, {
    path: '/v/:volumeId/admin/manage',
    name: 'manage',
    component: () => import('../views/admin/Manage.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  }, {
    path: '*',
    name: 'error',
    component: () => import('../views/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  firebase.auth().onAuthStateChanged(function (user) {
    if (requiresAuth) {
      if (user) {
        user.getIdToken(true).then(() => {
          user.getIdTokenResult().then((idTokenResult) => {
            const claims = idTokenResult.claims
            const isAdmin = claims.admin

            if (requiresAdmin && !isAdmin) {
              next({ name: 'signIn', query: { redirect_to: to.fullPath } })
            } else {
              next()
            }
          })
        })
      } else {
        next({ name: 'anonymous', query: { redirect_to: to.fullPath } })
      }
    } else {
      next()
    }
  })
})

export default router
