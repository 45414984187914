<template>
  <div id="app">
    <router-view/>
    <fa-icon :icon="['fas', 'paw']" class="sign fa-lg" />
  </div>
</template>


<script>
let vh = undefined
const setVhProperty = () => {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize', () => {
  setVhProperty()
})

setVhProperty()

export default {
  name: 'app'
}
</script>


<style lang="scss">
@import "./assets/css/buefy.scss";
@import "./assets/css/bulma/elements/button.css";

html, body {
  height: 100%;
  width: 100%;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  font-size: 14px;
  margin: 0;
  position: relative;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#app {
  background-color: #fff;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;

  > .sign {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #f9f9f9;
    pointer-events: none;
  }
}

.hero.is-fullheight {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
