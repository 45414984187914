import Vue from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from './utils/title'


// Firebase
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDAXS5827oEbXiFxgcGvJDT1fEnw24ogFM",
  authDomain: "shiftomo-letter.firebaseapp.com",
  projectId: "shiftomo-letter",
  storageBucket: "shiftomo-letter.appspot.com",
  messagingSenderId: "734528365214",
  appId: "1:734528365214:web:d365403340aecc15e9d18f",
  measurementId: "G-SLTT79NXRW"
}
firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()
export { firebase, db, auth }

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)


// Fontawsome
import { dom } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
library.add(fas, far, fab)
Vue.component('fa-icon', FontAwesomeIcon)


// Buefy
import Buefy from 'buefy'
Vue.use(Buefy, {
  defaultIconPack: 'fa',
})


// VeeValidate
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import { required, max, email, min } from 'vee-validate/dist/rules'
// extend('required', required)
extend('required', {
  ...required,
  message: 'この項目は入力必須です'
})
extend('email', email)
extend('max', max)
extend('min', min)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
localize('ja', ja)


// Tippy
import VueTippy, { TippyComponent } from 'vue-tippy'
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)


// Toasted
import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  theme: 'toasted-primary',
  position: 'bottom-center',
  containerClass: 'is-edge',
  iconPack: 'custom-class',
  duration : 5000
})


// JsonCSV
import JsonCSV from 'vue-json-csv'
Vue.component('download-csv', JsonCSV)


// Filters
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
})


Vue.mixin(titleMixin)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
